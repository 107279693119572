@keyframes highlight{
  0%{
    opacity: 100%;
    background-color: none;
  }
  100%{
    opacity: 50%;
  }
}

@keyframes highlight2{
  0%{
    opacity: 0%;
    background-color: none;
  }
  100%{
    opacity: 100%;
  }
}

@keyframes typing{
  0%{
    width: 0%;
  }
  100%{
    width: 100%;
  }
}

@keyframes cursor{
  0%{
    border-right: 2px solid #000;
  }
  50%{
    border-right: none;
  }
}

@keyframes loading {
  0%{
  margin-left: 50px;
  }
  50%{
  margin-left: 0px;
  }
  100%{
  margin-left: 50px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(243, 244, 245);
  background-image: url("./images/background.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100vw;
  height: 94vh;
}

.title-container {
  display: flex;
  flex-direction: column;
  margin-top: 6vh;
  margin-right: 6vw;
  text-align: right;
}

.title1, .title2 {
  align-items: center;
  margin-bottom: 0;
  margin-top: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

.title1 {
  font-size: 32pt;
}

.title2 {
  font-weight: 600;
}

.services-title {
  text-align: center;
  color: rgb(190, 190, 190);
  text-shadow: 0px 0px 8px rgb(50,50,50);
  text-shadow: 
        -1px -1px 0 rgb(100,100,100),
         1px -1px 0 rgb(100,100,100),
        -1px  1px 0 rgb(100,100,100),
         1px  1px 0 rgb(100,100,100),
         0px 0px 10px rgb(0,0,0);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 32pt;
}

.services-container{
  display: flex;
  width: 50vw;
  align-items: center;
  border: 5px solid black;
  border-radius: 10px;
  margin: auto;
  flex-wrap: wrap;
}

.service{
  width: 180px;
  border: none;
  background-color: rgb(243, 244, 245);
  margin: auto;
  background: none;
}

.service:hover {
  animation: highlight;
  animation-duration: 0.25s;
  opacity: 50%;
  /* background-color: red; */
}

.icon-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  /* background-color: rgb(245, 245, 245); */
  margin: 0%;
  width: 200px;
  height: 100px;
}

.icon-title{
  margin: 0;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
}

.pressed{
  background-color: rgb(150, 150, 150);
  background-size: 0%;
  border-radius: 500px;
}

.typing-container{
  display: flex;
  flex-direction: row;
  margin: auto;
  margin-top: 25px;
  flex-wrap: wrap;
  max-width: max-content;
  justify-content: center;
  gap: 10px;
}

.typing{
  font-size: 20px;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid #000;
  text-align: center;
  width: max-content;
  max-width: 95vw;
  margin: 0;
}

.typing-before{
  animation: typing 4s steps(40);
}

.typing-after{
  animation: cursor 1s infinite;
  width: auto;
  text-wrap: wrap;
}

.text-box {
  margin-top: 20px;
  padding: 5px;
  height: 12px;
  font-size: 20px;
  width: fit-content;
  animation: highlight2;
  animation-duration: 4s;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

.submit{
  text-align: center;
  margin: auto;
  width: 200px; 
}

.submit-button{
  margin-top: 20px;
  margin-bottom: 25px;
  text-align: center;
  border: 2px solid black;
  border-radius: 10px;
  color: rgb(190, 190, 190);
  text-shadow: 0px 0px 8px rgb(50,50,50);
  text-shadow: 
        -1px -1px 0 rgb(100,100,100),
         1px -1px 0 rgb(100,100,100),
        -1px  1px 0 rgb(100,100,100),
         1px  1px 0 rgb(100,100,100),
         0px 0px 10px rgb(0,0,0);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 20pt;
}

.submit-button:hover{
  animation: highlight 0.25s;
  opacity: 50%;
}

.load-text{
  text-align: center;
  margin: auto;
  margin-bottom: 0;
}

.results-container{
  display: flex;
  max-width: 90vw;
  margin: auto;
  gap: 10vw;
  flex-wrap: wrap;
  justify-content: center;
}

.results-child{
  width: 20vw;
  min-width: 300px;
  border: 5px solid black;
  border-radius: 5%;
  padding: 10px;
  text-align: center;
}

.desc{
  font-size: 16pt;
  font-weight: 600;
  text-align: center;
}

.link{
  color: rgb(255, 255, 255);
  text-shadow: 0px 0px 8px rgb(50,50,50);
  text-shadow: 
        -1px -1px 0 rgb(150,150,150),
         1px -1px 0 rgb(150,150,150),
        -1px  1px 0 rgb(150,150,150),
         1px  1px 0 rgb(150,150,150),
         0px 0px 10px rgb(0,0,0);
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-size: 24pt;
  font-weight: 600;
}